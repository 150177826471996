import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism"
import "./WhizPage.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  setActiveTab,
  setContactDrawerMode,
  setContactDrawerVisible,
  setVisibleContactID,
} from "../redux/App/App.actions"
import { useParams } from "react-router-dom"
import ProfileIcon from "../components/Icons/Profile.icon"
import whizWebSocketService from "../services/WhizWebSocketService"
import "../utils/testWebSocket" // Import the test script

export default function WhizPage({ show_person = false }) {
  const dispatch = useDispatch()
  const params = useParams()
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isTyping, setIsTyping] = useState(false)
  const [useWebSocket, setUseWebSocket] = useState(true)
  const [wsConnected, setWsConnected] = useState(false)
  const [connectionAttempts, setConnectionAttempts] = useState(0)
  const [connectionStatus, setConnectionStatus] = useState("initializing") // "initializing", "connected", "disconnected", "failed"
  const [wsUrl, setWsUrl] = useState("")
  const messagesEndRef = useRef(null)
  const inputRef = useRef(null)

  // Get access token from Redux store
  const accessToken = useSelector((state) => state.UserState.access_token)

  useEffect(() => {
    loadMessages()

    // Initialize WebSocket connection if we have an access token
    if (useWebSocket && accessToken) {
      initializeWebSocket()
    } else if (useWebSocket && !accessToken) {
      setConnectionStatus("failed")
      setError(
        "Authentication required. Please log in again if the problem persists."
      )
      setUseWebSocket(false)
    }

    // Cleanup on unmount
    return () => {
      if (useWebSocket) {
        cleanupWebSocket()
      }
    }
  }, [useWebSocket, accessToken])

  useEffect(() => {
    if (show_person && params.slug) {
      dispatch(setActiveTab(null))
      dispatch(setContactDrawerVisible(true))
      dispatch(setContactDrawerMode("view"))
      dispatch(setVisibleContactID(params.slug))
    }
  }, [show_person, params.slug, dispatch])

  useEffect(() => {
    scrollToBottom()
  }, [messages, isTyping])

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const parseMessage = (msg) => {
    if (msg.role === "assistant" && typeof msg.content === "string") {
      try {
        const parsed = JSON.parse(msg.content)
        if (parsed.content) {
          return {
            ...msg,
            parsedContent: parsed,
          }
        }
      } catch (e) {
        console.log("Failed to parse message content as JSON:", e)
      }
    }
    return msg
  }

  const initializeWebSocket = () => {
    setConnectionStatus("initializing")

    // Connect to WebSocket
    whizWebSocketService.connect()

    // Set the WebSocket URL for debugging
    if (process.env.NODE_ENV === "development") {
      try {
        // Get the WebSocket URL from the service
        const wsUrl = whizWebSocketService.getApiHostUrl()
        setWsUrl(wsUrl)
        console.log("WebSocket URL set to:", wsUrl)
      } catch (e) {
        console.error("Error setting WebSocket URL:", e)
      }
    }

    // Set up event listeners
    whizWebSocketService.on("message", handleWebSocketMessage)
    whizWebSocketService.on("error", handleWebSocketError)
    whizWebSocketService.on("connected", handleWebSocketConnected)
    whizWebSocketService.on("disconnected", handleWebSocketDisconnected)

    // Increment connection attempts
    setConnectionAttempts((prev) => prev + 1)
  }

  const cleanupWebSocket = () => {
    // Remove event listeners
    whizWebSocketService.off("message", handleWebSocketMessage)
    whizWebSocketService.off("error", handleWebSocketError)
    whizWebSocketService.off("connected", handleWebSocketConnected)
    whizWebSocketService.off("disconnected", handleWebSocketDisconnected)

    // Disconnect WebSocket
    whizWebSocketService.disconnect()

    // Update state
    setWsConnected(false)
    setConnectionStatus("disconnected")
  }

  const handleWebSocketConnected = (data) => {
    console.log("Connected to Whiz WebSocket", data)
    setWsConnected(true)
    setConnectionStatus("connected")
    setError(null)

    // If we received a subscribed flag, update the UI accordingly
    if (data && data.subscribed) {
      console.log("Successfully subscribed to WhizChannel")
    }
  }

  const handleWebSocketDisconnected = (data) => {
    console.log("Disconnected from Whiz WebSocket", data)
    setWsConnected(false)
    setConnectionStatus("disconnected")

    // If we've tried to connect multiple times and failed, show an error
    if (connectionAttempts > 2) {
      setConnectionStatus("failed")
      setError("WebSocket connection failed. Using standard API instead.")
      setUseWebSocket(false)
    }
  }

  const handleWebSocketMessage = (data) => {
    console.log("WebSocket message received:", data)

    if (data.type === "message_received") {
      // Message was received by the server, nothing to do
      return
    }

    if (data.type === "typing") {
      // Update typing indicator
      setIsTyping(data.typing)
      return
    }

    if (data.type === "new_message") {
      // Add the new message to the chat
      const newMsg = parseMessage(data.message)
      setMessages((prev) => {
        // Check if this message is already in the list
        const exists = prev.some(
          (msg) =>
            msg.id === newMsg.id ||
            (msg.content === newMsg.content && msg.role === newMsg.role)
        )

        if (exists) {
          return prev
        }

        return [...prev, newMsg]
      })

      // Hide typing indicator
      setIsTyping(false)
    }

    if (data.type === "error") {
      // Show error message
      setError(data.error.message)
      setIsTyping(false)
    }
  }

  const handleWebSocketError = (error) => {
    console.error("WebSocket error:", error)

    if (error.type === "auth_error") {
      setError("Authentication error. Please refresh the page and try again.")
      setConnectionStatus("failed")
    } else if (error.type === "config_error") {
      setError("Configuration error. API URL is not properly configured.")
      setConnectionStatus("failed")
    } else {
      setError("Connection error. Falling back to standard API.")
      setConnectionStatus("failed")
    }

    setUseWebSocket(false)
  }

  const loadMessages = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get("/v1/whiz")
      if (response.data.messages) {
        // Filter out any error messages and duplicates
        const uniqueMessages = response.data.messages
          .map(parseMessage)
          .filter(
            (msg, index, self) =>
              !msg.metadata?.error &&
              index ===
                self.findIndex(
                  (m) => m.content === msg.content && m.role === msg.role
                )
          )
        setMessages(uniqueMessages)
      }
    } catch (error) {
      console.error("Error loading messages:", error)
      setError("Failed to load conversation history. Please refresh the page.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!newMessage.trim()) return

    const userMessage = {
      content: newMessage.trim(),
      role: "user",
      created_at: new Date().toISOString(),
    }

    // Add user message to the chat immediately
    setMessages((prev) => [...prev, userMessage])
    setNewMessage("")
    setError(null)

    // Show typing indicator for assistant response
    setIsTyping(true)

    if (useWebSocket && wsConnected) {
      // Send message via WebSocket
      whizWebSocketService.sendMessage(userMessage.content)
    } else {
      // Fallback to REST API
      try {
        setIsLoading(true)
        const response = await axios.post("/v1/whiz", {
          content: userMessage.content,
        })

        // Update with actual messages from response
        if (response.data.messages) {
          setMessages((prev) => {
            // Remove the temporary user message
            const withoutTemp = prev.slice(0, -1)
            // Add only new messages that aren't duplicates
            const newMessages = response.data.messages
              .map(parseMessage)
              .filter(
                (newMsg) =>
                  !withoutTemp.some(
                    (existingMsg) =>
                      existingMsg.content === newMsg.content &&
                      existingMsg.role === newMsg.role
                  )
              )
            return [...withoutTemp, ...newMessages]
          })
        }

        // Handle any error message in the response
        if (response.data.error) {
          setError(response.data.error)
        }
      } catch (error) {
        console.error("Error sending message:", error)
        setError(
          error.response?.data?.error || "Failed to send message. Please try again."
        )
        // Remove the temporary user message if there was an error
        setMessages((prev) => prev.slice(0, -1))
      } finally {
        setIsLoading(false)
        setIsTyping(false)
      }
    }

    // Focus the input after sending
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handlePersonClick = (personId, e) => {
    dispatch(setActiveTab(null))
    dispatch(setContactDrawerVisible(true))
    dispatch(setContactDrawerMode("view"))
    dispatch(setVisibleContactID(personId))
  }

  const retryWebSocketConnection = () => {
    setConnectionAttempts(0)
    setError(null)
    setConnectionStatus("initializing")
    cleanupWebSocket()
    initializeWebSocket()
  }

  if (isLoading && messages.length === 0) {
    return (
      <div className="whiz-container">
        <div className="loading-state">
          <div className="loading-spinner" />
          <p>Loading conversation...</p>
        </div>
      </div>
    )
  }

  // Get debug info for tooltip
  const getDebugInfo = () => {
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:"
    const wsUrlString = wsUrl || `${protocol}//${window.location.host}/cable`
    const apiUrlString =
      process.env.REACT_APP_DEV_API_URL ||
      `${window.location.protocol}//${window.location.host}/api/`
    return `WebSocket URL: ${wsUrlString}
API URL: ${apiUrlString}
Authentication: ${wsConnected ? "Authenticated" : "Not authenticated"}
Connection Status: ${connectionStatus}`
  }

  return (
    <div className="whiz-container">
      {error && (
        <div className="error-message">
          {error}
          {error.includes("WebSocket") ||
          error.includes("Authentication") ||
          error.includes("Configuration") ? (
            <button className="retry-button" onClick={retryWebSocketConnection}>
              Retry Connection
            </button>
          ) : null}
        </div>
      )}

      <div className="connection-status-bar">
        <div
          className={`connection-indicator ${connectionStatus}`}
          title={process.env.NODE_ENV === "development" ? getDebugInfo() : undefined}
        >
          {connectionStatus === "initializing" && "Connecting..."}
          {connectionStatus === "connected" && "Connected"}
          {connectionStatus === "disconnected" && "Disconnected"}
          {connectionStatus === "failed" && "Connection Failed"}
        </div>
      </div>

      <div className="messages-container">
        {messages
          .filter(
            (message) => message.role === "user" || message.role === "assistant"
          )
          .map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              <div className="message-content">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || "")
                      const language = match ? match[1] : ""
                      return !inline ? (
                        <SyntaxHighlighter
                          style={tomorrow}
                          language={language}
                          PreTag="div"
                          {...props}
                        >
                          {String(children).replace(/\n$/, "")}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      )
                    },
                  }}
                >
                  {message.parsedContent?.content || message.content}
                </ReactMarkdown>
                {message.parsedContent?.user_context && (
                  <div className="user-context">
                    <strong>USER CONTEXT:</strong>{" "}
                    {message.parsedContent.user_context}
                  </div>
                )}
                {message.parsedContent?.mentioned_people &&
                  message.parsedContent.mentioned_people.length > 0 && (
                    <div className="mentioned-people">
                      {message.parsedContent.mentioned_people
                        .filter(
                          (person) =>
                            person.type === "person" && person.internal_person_id
                        )
                        .map((person, idx) => (
                          <button
                            key={idx}
                            className="person-link"
                            onClick={(e) =>
                              handlePersonClick(person.internal_person_id, e)
                            }
                          >
                            <ProfileIcon />
                            <span>
                              {person.display_string.replace(/[\[\]]/g, "")}
                            </span>
                          </button>
                        ))}
                    </div>
                  )}
                {message.parsedContent &&
                  Object.keys(message.parsedContent).some((key) =>
                    key.startsWith("mentioned_")
                  ) && (
                    <pre className="mentioned-data">
                      {Object.entries(message.parsedContent)
                        .filter(([key]) => key.startsWith("mentioned_"))
                        .map(
                          ([key, value]) =>
                            `${key}: ${JSON.stringify(value, null, 2)}`
                        )
                        .join("\n")}
                    </pre>
                  )}
              </div>
            </div>
          ))}

        {isTyping && (
          <div className="message assistant">
            <div className="message-content typing">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      <form className="message-form" onSubmit={handleSubmit}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          disabled={isLoading}
          ref={inputRef}
        />
        <button type="submit" disabled={isLoading || !newMessage.trim()}>
          Send
        </button>
      </form>
    </div>
  )
}
